<template>
  <div class="container">
    <div class="forgetRow">
      <div class="logo">
        <img :src="initLogo" style="height:40px;" />
        <span v-if="!onlyvalidemail">{{$t('login.registerAccount')}}</span>
      </div>
      <span class="actionLink">
        <span @click="$router.push('/login')">{{$t('forget.login')}}</span>
      </span>
    </div>
    <div class="main">
      <el-row class="div-body">
        <!--注册类型选项-->
        <div class="caption">
          <div class="register-type">
            <p :class="{'active': registerType===1}" @click="registerType=1">
              <i-icon name="iconchezhu"></i-icon>{{$t('register.registerType1')}}
            </p>
            <p :class="{'active': registerType===2}" v-if="!hideUser" @click="registerType=2">
              <i-icon name="iconqiye"></i-icon>{{$t('register.registerType2')}}
            </p>
          </div>
        </div>
        <!--车辆用户注册-->
        <div class="body-right" v-if="registerType==1">
          <ol>
            <li :class="{'active':step == 1}"><strong>1</strong>
              <h4>{{ accountType == 1 ? $t('register.step1') : $t('forget.email') }}</h4>
            </li>
            <li :class="{'active':step == 2}" v-if="!onlyvalidemail"><strong>2</strong>
              <h4>{{$t('register.step2')}}</h4>
            </li>
            <li :class="{'active':step == 3}" v-if="!onlyvalidemail"><strong>3</strong>
              <h4>{{$t('register.step3')}}</h4>
            </li>
            <li :class="{'active':step == 4}" v-if="!onlyvalidemail"><strong>4</strong>
              <h4>{{$t('register.step4')}}</h4>
            </li>
          </ol>

          <!--步骤一：输入手机号码-->
          <el-form label-width="10em" label-position="right" :model="form" ref="form1" key="stepOne" :rules="rules"
            v-if="step == 1">
            <el-form-item :label="$t('forget.mobile')" prop="mobile" key="mobile" v-if="accountType==1">
              <el-input v-model.number="form.mobile" :placeholder="$t('register.pleaseInputMobile')"
                style="width:240px;" :maxlength="11" oninput="value=value.replace(/[^\d]/g, '')"></el-input>
              <span class="changeAccout" @click="accountType = 1 - accountType">{{$t('forget.useEmail')}}</span>
            </el-form-item>
            <el-form-item :label="$t('forget.email')" prop="email" key="email" v-else>
              <el-input v-model.trim="form.email" :placeholder="$t('forget.pleaseInputEmail')" style="width: 240px;"
                :maxlength="64"></el-input>
              <span class="changeAccout" @click="accountType = 1 - accountType"
                v-if="$i18n.locale == 'zh'">{{$t('forget.useMobile')}}</span>
            </el-form-item>
            <el-form-item :label="$t('forget.valid')" key="valid">
              <div class="valid-wrapper" style="">
                <el-slider v-model="rate" :show-tooltip="false" @change="rateChange" :disabled="rate==100"></el-slider>
                <p class="valid-tip success" v-if="rate==100">{{$t('forget.validSuccess')}}</p>
                <p class="valid-tip" v-else>{{$t('forget.pleaseDrag')}}</p>
              </div>
            </el-form-item>
            <el-form-item key="stepOneButton">
              <el-button @click="stepOne" :loading="loading" :disabled="rate!=100" style="width: 100px;">
                {{$t('register.nextStep')}}</el-button>
            </el-form-item>
          </el-form>

          <!--步骤二：输入姓名、登录密码-->
          <el-form label-width="10em" label-position="right" :model="form" ref="form2" key="stepTwo" :rules="rules"
            v-if="step == 2">
            <el-form-item :label="$t('register.name')" prop="contacter" key="name">
              <el-input v-model.trim="form.contacter" autocomplete="off" :placeholder="$t('register.pleaseInputName')"
                style="width:240px;" :maxlength="20"></el-input>
            </el-form-item>
             <el-form-item :label="$t('register.sim2')" prop="sim2" key="sim2">
              <el-input v-model.trim="form.sim2" :placeholder="$t('register.pleaseInputSim2')" style="width:240px;"
                :maxlength="20"></el-input>
                <span class="tipsAccout">{{$t('register.placeNametips')}}</span>
            </el-form-item>
            <el-form-item :label="$t('register.password')" prop="password" key="password">
              <el-input v-model="form.password" autocomplete="off" type="text" style="width:240px;"
                :placeholder="$t('register.pleaseInputPassword')" :maxlength="18"></el-input>
            </el-form-item>
            <!-- <el-form-item :label="$t('register.passwordAgain')" prop="passwordAgain" key="passwordAgain">
              <el-input v-model="form.passwordAgain" autocomplete="off" type="password" style="width:240px;" :placeholder="$t('register.pleaseInputPasswordAgain')" :maxlength="6"></el-input>
            </el-form-item> -->
            <el-form-item key="stepTwoButton">
              <el-button @click="stepTwo" :loading="loading" style="width: 100px;">{{$t('register.nextStep')}}
              </el-button>
            </el-form-item>
          </el-form>

          <!--步骤三：输入SIM卡号、设备ID号-->
          <el-form label-width="10em" label-position="right" :model="form" ref="form3" key="stepThree" :rules="rules"
            v-if="step == 3">
            <template v-if="form.typeID==='0'">
              <el-form-item :label="$t('register.vehicleName')" prop="vehicleName">
                <el-input v-model.trim="form.vehicleName" :placeholder="$t('register.pleaseInputVehiclePlate')"
                  style="width:240px;" :maxlength="20"></el-input>
              </el-form-item>
            </template>
            <template v-if="form.typeID==='1'">
              <el-form-item :label="$t('register.personName')" prop="vehicleName1">
                <el-input v-model.trim="form.vehicleName1" :placeholder="$t('register.pleaseInputVehiclePlate1')"
                  style="width:240px;" :maxlength="20"></el-input>
              </el-form-item>
            </template>
            <template v-if="form.typeID==='2'">
              <el-form-item :label="$t('register.articleName')" prop="vehicleName2">
                <el-input v-model.trim="form.vehicleName2" :placeholder="$t('register.pleaseInputVehiclePlate2')"
                  style="width:240px;" :maxlength="20"></el-input>
              </el-form-item>
            </template>
            <el-form-item :label="$t('register.sim')" prop="sim" key="sim">
              <el-input v-model.trim="form.sim" :placeholder="$t('register.pleaseInputSim')" style="width:240px;"
                :maxlength="20"></el-input>
            </el-form-item>
            <el-form-item :label="$t('register.typeID')" prop="typeID" key="typeID">
              <el-radio-group v-model="form.typeID" @change="changeTypeID">
                <el-radio label="0">{{$t('register.vehicle')}}</el-radio>
                <el-radio label="1">{{$t('register.person')}}</el-radio>
                <el-radio label="2">{{$t('register.article')}}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item key="stepThreeButton">
              <el-button @click="stepThree" :loading="loading" :disabled="rate!=100" style="width: 100px;">
                {{$t('common.ok')}}</el-button>
            </el-form-item>
          </el-form>

          <!--步骤四：注册成功、跳转登录页面-->
          <div style="text-align:center;" v-if="step == 4">
            <p><i class="el-icon-success" style="color:#67C23A;font-size:50px;margin-top:24px;"></i></p>
            <p style="margin-top:24px;color:#999;">{{$t('register.registerSuccess', {time: waitTime})}} <router-link
                to="/login" tag="a" class="i-link">{{$t('register.pleaseLogin')}}</router-link>
            </p>
            <p class="successtip">
              {{ registerType == 1 ? $t('register.pleaseLoginTip1') : (accountType===1? $t('register.pleaseLoginTip2'): $t('register.pleaseLoginTip3'))}}
            </p>
          </div>

          <div class="dialogSms" v-if="smsCodeVisible">
            <p class="dialogTitle">
              {{accountType===1? $t('register.validTitle'):$t('register.validTitleEmail')}}
              <i class="el-icon-close" @click="closeSmsCode"></i>
            </p>
            <p class="dialogTip">
              <i class="el-icon-warning"></i>
              {{accountType===1?$t('register.validTip'):$t('register.validTipEmail')}}
            </p>
            <el-form label-width="9em" label-position="right">
              <el-form-item :label="$t('register.mobile')" v-if="accountType===1">
                {{form.mobile}}
              </el-form-item>
              <el-form-item :label="$t('forget.email')" v-else>
                {{form.email}}
              </el-form-item>
              <el-form-item :label="$t('register.validCode')">
                <el-input style="width:100px;margin-right:12px;" :maxlength="8" v-model="smsCode"></el-input>
                <el-button style="min-width:100px" :disabled="!!smsTime" @click="validSmsCode">
                  {{ `${smsTime > 0 ? $t('register.tryAgain')+'('+smsTime+')' : $t('register.sendButton')}`}}
                </el-button>
                <p class="formTip"><span><i class="el-icon-circle-check" style="color: #6bc827;font-size:14px;">
                    </i></span> {{accountType===1?$t('register.validTip2'):$t('register.validTip2Email')}}</p>
              </el-form-item>

              <el-form-item>
                <el-button type="primary" style="min-width:100px" :disabled="!smsCode" @click="validCode">
                  {{$t('common.ok')}}
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!--企业用户注册-->
        <div class="body-right" v-else>

          <ol>
            <li :class="{'active':step == 1}"> <strong>1</strong>
              <h4>{{accountType == 1 ? $t('register.step1') : $t('forget.email')}}</h4>
            </li>
            <li :class="{'active':step == 2}"> <strong>2</strong>
              <h4>{{$t('register.step2')}}</h4>
            </li>
            <li :class="{'active':step == 4}"><strong>3</strong>
              <h4>{{$t('register.step4')}}</h4>
            </li>
          </ol>

          <!--步骤一：输入手机号码-->
          <el-form label-width="10em" label-position="right" :model="form" ref="form1" key="stepOne" :rules="rules"
            v-if="step == 1">
            <el-form-item :label="$t('forget.mobile')" prop="mobile" key="mobile" v-if="accountType==1">
              <el-input v-model.number="form.mobile" :placeholder="$t('register.pleaseInputMobile')"
                style="width:240px;" :maxlength="11" oninput="value=value.replace(/[^\d]/g, '')"></el-input>
              <span class="changeAccout" @click="accountType = 1 - accountType">{{$t('forget.useEmail')}}</span>
            </el-form-item>
            <el-form-item :label="$t('forget.email')" prop="email" key="email" v-else>
              <el-input v-model.trim="form.email" :placeholder="$t('forget.pleaseInputEmail')" style="width: 240px;"
                :maxlength="64"></el-input>
              <span class="changeAccout" @click="accountType = 1 - accountType"
                v-if="$i18n.locale == 'zh'">{{$t('forget.useMobile')}}</span>
            </el-form-item>
            <el-form-item :label="$t('forget.valid')" key="valid">
              <div class="valid-wrapper" style="">
                <el-slider v-model="rate" :show-tooltip="false" @change="rateChange" :disabled="rate==100"></el-slider>
                <p class="valid-tip success" v-if="rate==100">{{$t('forget.validSuccess')}}</p>
                <p class="valid-tip" v-else>{{$t('forget.pleaseDrag')}}</p>
              </div>
            </el-form-item>
            <el-form-item key="stepOneButton">
              <el-button @click="stepOne" :loading="loading" :disabled="rate!=100" style="width: 100px;">
                {{$t('register.nextStep')}}</el-button>
            </el-form-item>
          </el-form>

          <!--步骤二：输入姓名、登录密码-->
          <el-form label-width="10em" label-position="right" :model="form" ref="form5" key="stepTwo" :rules="rules"
            v-if="step == 2">
            <el-form-item :label="$t('register.companyName')" prop="holdName" key="holdName">
              <el-input v-model.trim="form.holdName" autocomplete="off"
                :placeholder="$t('register.pleaseInputEnterprise')" style="width:240px;" :maxlength="20"></el-input>
            </el-form-item>
            <el-form-item :label="$t('register.contacter')" prop="contacter" key="name">
              <el-input v-model.trim="form.contacter" autocomplete="off" :placeholder="$t('register.pleaseInputName')"
                style="width:240px;" :maxlength="20"></el-input>
            </el-form-item>
            <!-- <el-form-item :label="$t('register.address')" prop="address" key="address">
              <el-input v-model.trim="form.address" autocomplete="off" :placeholder="$t('register.pleaseInputAddress')"
                style="width:240px;" :maxlength="20"></el-input>
            </el-form-item> -->
            <el-form-item :label="$t('register.password')" prop="password" key="password">
              <el-input v-model="form.password" autocomplete="off" type="password" style="width:240px;"
                :placeholder="$t('register.pleaseInputPassword')" :maxlength="18"></el-input>
            </el-form-item>
            <el-form-item :label="$t('register.passwordAgain')" prop="passwordAgain" key="passwordAgain">
              <el-input v-model="form.passwordAgain" autocomplete="off" type="password" style="width:240px;"
                :placeholder="$t('register.pleaseInputPasswordAgain')" :maxlength="18"></el-input>
            </el-form-item>
            <el-form-item key="stepTwoButton">
              <el-button @click="stepTwo2" :loading="loading" style="width: 100px;">{{$t('register.nextStep')}}
              </el-button>
            </el-form-item>
          </el-form>

          <!--步骤三：注册成功、跳转登录页面-->

          <div style="text-align:center;" v-if="step == 4">
            <p><i class="el-icon-success" style="color:#67C23A;font-size:50px;margin-top:24px;"></i></p>
            <p style="margin-top:24px;color:#999;">{{$t('register.registerSuccess', {time: waitTime})}}<router-link
                to="/login" tag="a" class="i-link">{{$t('register.pleaseLogin')}}</router-link>
            </p>
            <p class="successtip">
              {{ registerType == 1 ? $t('register.pleaseLoginTip1') : $t('register.pleaseLoginTip2')}}</p>
          </div>

          <div class="dialogSms" v-if="smsCodeVisible">
            <p class="dialogTitle">
              {{accountType===1? $t('register.validTitle'):$t('register.validTitleEmail')}}
              <i class="el-icon-close" @click="closeSmsCode"></i>
            </p>
            <p class="dialogTip">
              <i class="el-icon-warning"></i>
              {{accountType===1?$t('register.validTip'):$t('register.validTipEmail')}}
            </p>
            <el-form label-width="9em" label-position="right">
              <el-form-item :label="$t('register.mobile')" v-if="accountType===1">
                {{form.mobile}}
              </el-form-item>
              <el-form-item :label="$t('forget.email')" v-else>
                {{form.email}}
              </el-form-item>
              <el-form-item :label="$t('register.validCode')">
                <el-input style="width:100px;margin-right:12px;" :maxlength="8" v-model.trim="smsCode"></el-input>
                <el-button style="min-width:100px" :disabled="!!smsTime" @click="validSmsCode">
                  {{ `${smsTime > 0 ? $t('register.tryAgain')+'('+smsTime+')' : $t('register.sendButton')}`}}
                </el-button>
                <p class="formTip"><span><i class="el-icon-circle-check" style="color: #6bc827;font-size:14px;">
                    </i></span> {{accountType===1?$t('register.validTip2'):$t('register.validTip2Email')}}</p>
              </el-form-item>

              <el-form-item>
                <el-button type="primary" style="min-width:100px" :disabled="!smsCode" @click="validCode">
                  {{$t('common.ok')}}
                </el-button>
              </el-form-item>
            </el-form>
          </div>

        </div>
      </el-row>
    </div>
  </div>
</template>

<script>
  import {
    getLastLoginInfo
  } from '@/common/cache'
  import {
    BASE_URL
  } from '@/api/config'
  import {
    SendVerifyCode,
    RegisterVehicle,
    RegisterAccount,
    ChangeEmailByCaptcha,
    CheckVerifyCode
  } from '@/api/login'
  export default {
    data() {
      var validPass = (rule, value, cb) => {
        if (value != this.form.password) {
          cb(new Error(this.$t('register.checkPassError')))
        } else {
          cb()
        }
      }
      var validPass2 = (rule, value, cb) => {
        if (!/^[a-zA-Z0-9]{6,18}$/.test(value)) {
          cb(new Error(this.$t('register.pleaseInputSixNumber2')))
        } else {
          cb()
        }
      }
      var validNumber = (rule, value, cb) => {
        if (!/^\d{6,20}$/.test(value)) {
          cb(new Error(this.$t('register.errorFormat')))
        } else {
          cb()
        }
      }

      var validPhone = (rule, value, cb) => {
        if (!/^1\d{10}$/.test(value)) {
          cb(new Error(this.$t('register.errorFormat')))
        } else {
          cb()
        }
      }

      var validEmail = (rule, value, cb) => {
        if (!/^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$/.test(value)) {
          cb(new Error(this.$t('forget.errorFormatEmail')))
        } else {
          cb()
        }
      }

      return {
        registerType: 2, // 1 车主 2 企业
        step: 1, // 步骤

        rate: 0, // 滑动验证

        form: {
          mobile: null, // 手机号
          contacter: null, // 联系人
          queryPassword: null, // 密码
          queryPasswordAgain: null, // 密码
          vehicleName: null, // 车牌
          vehicleName1: null, // 车牌
          vehicleName2: null, // 车牌
          sim: null, // SIM卡号
          sim2: null, // 终端ID号
          typeID:0,
          holdName: null, // 用户名
          holdContacter: null, // 联系人
          address: null, // 地址
          password: null, // 密码
          passwordAgain: null, // 密码
          email: null
        },
        rules: {
          mobile: [{
              required: true,
              message: this.$t('register.pleaseInputMobile'),
              trigger: 'blur'
            },
            {
              type: 'number',
              message: this.$t('register.errorPhone'),
              trigger: 'blur'
            },
            {
              validator: validPhone,
              trigger: 'blur'
            }
          ],
          email: [{
              required: true,
              message: this.$t('forget.errorFormatEmail'),
              trigger: 'blur'
            },
            {
              validator: validEmail,
              trigger: 'blur'
            }],
          contacter: [{
            required: true,
            message: this.$t('register.pleaseInputContacter'),
            trigger: 'blur'
          }],
          passwordAgain: [{
              required: true,
              message: this.$t('register.pleaseInputPasswordAgain'),
              trigger: 'blur'
            },
            {
              validator: validPass,
              trigger: 'blur'
            },
            {
              validator: validPass2,
              trigger: 'blur'
            }],
          vehicleName: [{
            required: true,
            message: this.$t('register.pleaseInputVehiclePlate'),
            trigger: 'blur'
          }],
          vehicleName1: [{
            required: true,
            message: this.$t('register.pleaseInputVehiclePlate1'),
            trigger: 'blur'
          }],
          vehicleName2: [{
            required: true,
            message: this.$t('register.pleaseInputVehiclePlate2'),
            trigger: 'blur'
          }],
          sim2: [{
              required: true,
              message: this.$t('register.pleaseInputSim2'),
              trigger: 'blur'
            },
            {
              validator: validNumber,
              trigger: 'blur'
            }],
          sim: [{
              required: true,
              message: this.$t('register.pleaseInputSim'),
              trigger: 'blur'
            },
            {
              validator: validNumber,
              trigger: 'blur'
            } ],
          address: [{
            required: true,
            message: this.$t('register.pleaseInputAddress'),
            trigger: 'blur'
          }, ],
          holdName: [{
            required: true,
            message: this.$t('register.pleaseInputName'),
            trigger: 'blur'
          }],
          holdContacter: [{
            required: true,
            message: this.$t('register.pleaseInputContacter'),
            trigger: 'blur'
          } ],
          password: [{
              required: true,
              message: this.$t('register.pleaseInputPassword'),
              trigger: 'blur'
            },
            {
              validator: validPass2,
              trigger: 'blur',
            }
          ]
        },
        accountType: 1, // 1:手机号码注册 0: Email注册

        smsCodeVisible: false,

        smsTime: 0,
        smsCode: null,
        waitTime: 5,
        loading: false,
        hideUser: false,
        onlyvalidemail: false,
        objectid: 0
      }
    },
    watch: {
      registerType() {
        this.step = 1
        this.form = {
          mobile: null, // 手机号
          contacter: null, // 联系人
          queryPassword: null, // 密码
          queryPasswordAgain: null, // 密码
          vehicleName: null, // 车牌
          vehicleName1: null, // 车牌
          vehicleName2: null, // 车牌
          sim: null, // SIM卡号
          sim2: null, // 终端ID号
          typeID:"0",
          holdName: null, // 用户名
          holdContacter: null, // 联系人
          address: null, // 地址
          password: null, // 密码
          passwordAgain: null // 密码
        }
        this.rate = 0
        this.smsCodeVisible = false
        this.smsCode = null
      },
      // "form.typeID":{
      //   handler: function(n,o) {
      //       if(n!==o){
      //         this.$refs.form3&&
      //       }
      //    },
      // }
    },
    created() {
      const lastLoginInfo = getLastLoginInfo()
      this.initLogo = lastLoginInfo.PlatformLogo || `${BASE_URL}/assets/Logo?holdid=1`
      // this.validCode = () => {}
      if (this.$i18n.locale != 'zh') {
        this.accountType = 0
      }

      if (this.$route.query.registerType !== undefined) {
        this.registerType = this.$route.query.registerType
      }
      if (this.$route.query.accountType !== undefined) {
        this.accountType = this.$route.query.accountType
      }
      if (this.$route.query.hideUser === '1') {
        this.hideUser = true
      }
      if (this.$route.query.onlyvalidemail === '1') {
        this.onlyvalidemail = true
      }

      this.objectid = this.$route.query.objectid

    },
    methods: {
      changeTypeID(e){
        // this.form.vehicleName =''
        this.$refs.form3.resetFields();
        this.form.typeID = e
      },
      rateChange(val) {
        if (val != 100) {
          this.rate = 0
        }
      },

      runStep() {
        this.stepOne().then(this.stepTwo).then(this.stepThree).then(this.stepFour).then(this.forward)
      },

      // 手机验证码
      validEmail() {
        let promise = new Promise((resolve, reject) => {
          // 发送验证码
          this.loading = true
          var to = this.accountType == 1 ? this.form.mobile : this.form.email
          SendVerifyCode(to, this.accountType, 3).then(ret => {
            this.loading = false
            if (ret.data.errCode) {
              alert(ret.data.errMsg)
              return reject()
            }
            this.smsCodeVisible = true
            // let code = ret.data.code
            this.guidVal = ret.data.guid
            ChangeEmailByCaptcha(this.guidVal, this.smsCode, this.objectid).then(ret => {
              if (!ret.data.ErrCode) {
                this.smsCodeVisible = false
                this.$message({
                  message: 'Thank you. you can login now.',
                  type: 'success'
                })
                this.$router.push('/login')
                resolve()
              } else {
                alert(this.$t(ret.data.ErrMsg))
              }
            })
            //this.validCode = () => {
            // if (this.smsCode == code) {
            //   this.smsCodeVisible = false
            //   resolve()
            // } else {
            //   alert(this.$t('register.errorValidCode'))
            // }

            //}
            this.startTimer()
          })
        })
        return promise
      },
      validCode() {
        CheckVerifyCode(this.smsCode, this.smsGuid).then(ret => {
            if (ret.data.ErrCode) {
              alert(this.$t('register.errorValidCode'))
              return
            }
            this.smsCodeVisible = false
            this.step = 2
          })

          .catch(err => {
            console.error(err)
          })
      },
      // 输入手机号码
      stepOne() {

        if (this.onlyvalidemail) {
          this.$refs.form1.validate().then(this.validEmail)
          return
        }

        this.$refs.form1.validate().then(this.validSmsCode).then(() => {
          // 短信验证成功
          console.log("短信发送成功")
        })
      },
      // 输入名称、登录密码（注册车辆用户）
      stepTwo() {
        this.$refs.form2.validate().then(() => {
          // 密码表单验证成功
          this.step = 3
        })
      },
      // 输入车牌、终端ID号（注册车辆用户）
      stepThree() {
        this.$refs.form3.validate().then(() => {
          // 设备ID表单验证成功
          this.loading = true

          var telOrEmail = this.accountType === 1 ? this.form.mobile : this.form.email
          let deviceName = this.form.typeID==="1"?this.form.vehicleName:(this.form.typeID==="2"?this.form.vehicleName2:this.form.vehicleName)
          RegisterVehicle(telOrEmail,
              this.form.contacter,
              this.form.password,
              deviceName,
              this.form.sim,
              this.form.sim2,
              this.form.typeID)
            .then(ret => {
              this.loading = false
              if (ret.data.errCode) {
                this.$alert(ret.data.errMsg)
                return
              }
              this.step = 4
              this.startWaitTimer()
            })
            .catch(err => {
              console.error(err)
              this.loading = false
            })
        })
      },
      // 显示注册成功界面并跳转登录界面
      stepFour() {

      },
      // 输入公司名称、联系人、地址等（注册企业用户）
      stepTwo2() {
        this.$refs.form5.validate().then(() => {
          // 密码表单验证成功
          this.loading = true

          var telOrEmail = this.accountType === 1 ? this.form.mobile : this.form.email

          RegisterAccount(telOrEmail,
              this.form.holdName,
              this.form.contacter,
              this.form.address,
              this.form.password)
            .then(ret => {
              this.loading = false
              if (ret.data.errCode) {
                this.$alert(ret.data.errMsg)
                return
              }
              this.step = 4
              this.startWaitTimer()
            })
            .catch(err => {
              console.error(err)
              this.loading = false
            })
        })
      },

      forward() {

      },

      // 手机验证码
      validSmsCode() {
        let promise = new Promise((resolve, reject) => {
          // 发送验证码
          this.loading = true
          var to = this.accountType == 1 ? this.form.mobile : this.form.email
          SendVerifyCode(to, this.accountType, 3 - this.registerType).then(ret => {
            this.loading = false
            if (ret.data.errCode) {
              alert(ret.data.errMsg)
              return reject()
            }
            this.smsCodeVisible = true
            this.smsCode = '';
            this.smsGuid = ret.data.guid;

            // let code = ret.data.code
            // this.validCode = () => {
            //   if (this.smsCode == code) {
            //     this.smsCodeVisible = false
            //     resolve()
            //   } else {
            //     alert(this.$t('register.errorValidCode'))
            //   }
            // }
            this.startTimer()
          })
        })
        return promise
      },

      startTimer() {
        if (this.smsTime > 0) {
          return
        }

        this.smsTime = 60
        const decorate = () => {
          if (this.smsTime <= 0) {
            return
          }
          this.smsTime--
          setTimeout(decorate, 1000)
        }

        decorate()
      },

      startWaitTimer() {
        if (this.waitTime == 0) {
          return
        }

        this.waitTime = 5
        const decorate = () => {
          if (this.waitTime <= 1) {
            this.$router.push('/login')
            return
          }
          this.waitTime--
          setTimeout(decorate, 1000)
        }

        decorate()
      },

      closeSmsCode() {
        this.smsCodeVisible = false
      }
    }
  }

</script>

<style lang="scss" scoped>
  $maxWidth: 75%;
  $formWidth: 480px;

  .forgetRow {
    max-width: $maxWidth;
    padding-top: 40px;
    height: 110px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .logo {
      display: flex;
      line-height: 42px;
      font-size: 14px;
    }

    .actionLink {
      font-weight: 800;
      color: #1890ff;
      line-height: 42px;

      .split {
        color: black;
        margin: 0 4px;
      }

      span:not(.split):hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .tip {
    color: #606266;
    max-width: $formWidth;
    margin: 0 auto;
    margin-bottom: 32px;
    font-size: 14px;
  }

  .div-content {
    max-width: $maxWidth;
    margin: 0 auto;
    padding: 60px;

  }

  .div-body {
    max-width: $maxWidth;
    margin: 0 auto;

    .caption {
      display: flex;
      border-bottom: 1px solid #ccc;

      .register-type {
        border: 1px solid #ccc;
        border-bottom: none;
        border-radius: 3px;
        display: flex;
        position: relative;
        top: 1px;

        p {
          padding: 0px 20px;
          line-height: 42px;
          height: 42px;
          font-size: 14px;
          color: #000;
          align-items: center;
          transition: color .3s ease;
          white-space: nowrap;
          border-right: 1px solid #ccc;

          .icon {
            font-size: 20px;
            margin-right: 15px;
          }

          .el-icon-caret-right {
            margin-left: 4px;
            visibility: hidden;
          }

          &:hover {
            // font-weight: 600;
            cursor: pointer;
          }

          &.active {
            // font-weight: 600;
            border-bottom: 1px solid #fff;
            color: #409EFF;

            .el-icon-caret-right {
              margin-left: 4px;
              visibility: visible;
            }
          }
        }

        p:nth-last-child(1) {
          border-right: none
        }

        hr {
          margin: 16px 11px;
          border: 0;
          border-top: 1px solid #E8E8E8;
          height: 0;
        }
      }
    }

    .body-left {
      border-right: 1px solid #E8E8E8;
      padding: 0 15px;
      height: 500px;


    }

    .body-right {
      position: relative;

      ol {
        padding: 0;
        text-align: center;
        list-style: none;
        display: flex;
        width: 800px;
        margin: 0 auto;
        padding-top: 50px;
        padding-bottom: 80px;

        li {
          color: #999;
          // width: 150px;
          // display: inline-block;
          // margin-left: 30px;
          flex-grow: 1;
          width: 0;
          font-size: 16px;
          height: 40px;
          border-bottom: 2px dotted #cbd4dd;
          position: relative;

          strong {
            display: block;
            background-color: #cbd4dd;
            width: 40px;
            height: 40px;
            border-radius: 20px;
            text-align: center;
            line-height: 40px;
            font-size: 18px;
            color: #fff;
            position: absolute;
            top: 20px;
            left: -20px;

          }

          h4 {
            width:200px;
            text-align: center;
            font-size: 16px;
            line-height: 38px;
            color: #C0C4CC;
            position: absolute;
            margin-left: -100px;
            top: 60px;
          }

          &.active {
            // color: #F20C0C;
            // border-bottom: 2px solid #ff4700;

            strong {
              display: block;
              background-color: #1890ff;
            }

            h4 {
              color: #409EFF;
            }
          }
        }

        li:nth-last-child(1) {
          flex-grow: 0;
        }

      }

      .successtip {
        margin: 20px 24px 0;
        padding: 8px 24px;
        background-color: #FAFAFA;
        color: #409EFF;
        font-size: 14px;
      }

      .dialogSms {
        position: absolute;
        z-index: 5;
        top: 112px;
        left: 45px;
        width: 100%;
        height: 320px;
        border: 2px solid #dedede;
        background-color: #FFF;
        border-top: 2px solid #ff4a00;
        padding: 20px;

        .dialogTitle {
          font: 16px/1.5 tahoma, arial, 'Hiragino Sans GB', \5b8b\4f53, sans-serif;
          font-weight: bolder;
          margin-bottom: 15px;

          .el-icon-close {
            float: right;
            cursor: pointer;
          }
        }

        .dialogTip {
          padding-top: 7px;
          padding-bottom: 14px;
          font: 14px/1.5 "Microsoft YaHei", tahoma, arial, 'Hiragino Sans GB', '\5b8b\4f53', sans-serif;
          font-weight: 200;
          font-size: 13px;
          color: #3c3c3c;

          .el-icon-warning {
            color: #00bfff;
          }
        }

        .el-form {
          width: 400px;
          margin: 0 auto;

          .formTip {
            font-size: 12px;
          }
        }
      }

      &>.el-form {
        max-width: $formWidth;
        margin: 0 auto;
        position: relative;
        z-index: 4;

        & ::v-deep .el-form-item__label {
          padding-right: 24px;
        }
      }

      .valid-wrapper {
        width: 240px;
        padding-right: 32px;
        background-color: #E8E8E8;
        position: relative;

        .valid-tip {
          position: absolute;
          width: 240px;
          top: 0;
          left: 0;
          font-size: 12px;
          height: 38px;
          line-height: 38px;
          // text-align: center;
          text-indent: 35px;
          color: rgb(108, 108, 108);
          z-index: 4;
          user-select: none;

          &.success {
            color: #FFF;
          }
        }
      }

      .el-slider {
        & ::v-deep {
          .el-slider__runway {
            margin: 0;
            height: 38px;
            border-radius: 0;
            background-color: #E8E8E8;

            .el-slider__bar {
              height: 38px;
              background-color: #7AC23C;
            }

            &.disabled {
              .el-slider__bar {
                background-color: #78D02E;
              }
            }
          }

          .el-slider__button-wrapper {
            transform: none;
            height: 38px;
            width: 30px;
            background-color: #FFF;
            top: 1px !important;
            margin-left: 1px;
            outline: none;

            .el-slider__button {
              visibility: hidden;
            }
          }
        }
      }
    }
  }

  .changeAccout {
    color: #F20C0C;
    margin-left: 12px;
    cursor: pointer;
  }
  .tipsAccout {
    color: #F20C0C;
    margin-left: 12px;
    cursor: pointer;
    font-size: 12px;
  }

</style>
